import React, { useState } from "react";
import SortableTree, { toggleExpandedForAll, getFlatDataFromTree } from 'react-sortable-tree';
import { Button } from '@paljs/ui/Button';
import LoadingSpinner from "../../pages/loader";
import _ from "lodash";
import { toast } from 'react-toastify';
import { koot_falan } from "../../helpers/utils";

const getNodeKey = ({ node }) => node.uid;

const tree = ({ treeData, setTreeData, onNodeClick, isLoading, selectedPath, suggestedRelationObj, addRelationFromSuggestedData }) => {
    const [statusMessage, setStatusMessage] = useState('')
    const [suggestedSelectedNode, setSuggestedSelectedNode] = useState();

    const lookChildHavingMatchingUid = (obj) => {
        //here looking for an immediate child having matching uid
        let children = obj['children'] || [];
        for (let i = 0; i < children.length; i++) {
            let child = children[i];
            if (child['matching_uid']) {
                return child['matching_uid'];
            }
        }
        return false;
    }
    const lookParentHavingMatchingUid = (tree_data, id) => {
        for (let i = 0; i < tree_data.length; i++) {
            let obj = tree_data[i];
            const matchingId = searchforMatchingUid(obj, id)
            if (matchingId) {
                return matchingId
            }
        }
        return false;

    }

    const searchforMatchingUid = (obj, id) => {
        if (obj.uid == id) {
            if (obj.matching_uid) {
                return obj.matching_uid
            } else {
                return false
            }
        }
        let children = obj['children'] || [];
        for (let i = 0; i < children.length; i++) {
            let result = searchforMatchingUid(children[i], id)
            if (result) {
                return result;
            }
        }
        return false
    }

    const addRelative = (node, path) => {
        if (path.length == 1) {
            const matchingUid = lookChildHavingMatchingUid(node)
            if (matchingUid) {
                addRelationFromSuggestedData(matchingUid, node, "ADD_NODE", { relation: 'parent', gender: 'male' })
            } else {
                toast.error('add immediate relations first')
            }
        }
        if (path.length > 1) {
            const matchingUid = lookParentHavingMatchingUid(treeData, path[path.length - 2])
            if (matchingUid) {
                addRelationFromSuggestedData(matchingUid, node, "ADD_NODE", { relation: 'kid', gender: node.gender })
            } else {
                const uid = lookChildHavingMatchingUid(node)
                if (uid) {
                    addRelationFromSuggestedData(matchingUid, node, "ADD_NODE", { relation: 'parent', gender: 'male' })
                } else {
                    toast.error('add immediate relations first')
                }
            }
        }

    }


    const updateDetails = (id, node) => {
        addRelationFromSuggestedData(id, node, "UPDATE_DETAILS", {})
    }

    const addSpouseFromSuggestedData = (matchingUid, node) => {
        addRelationFromSuggestedData(matchingUid, node, "ADD_NODE", { relation: 'spouse', gender: node.gender })
    }


    const sortableTree = (
        <>
            <SortableTree
                treeData={treeData}
                onChange={(data) => setTreeData(data)}
                getNodeKey={getNodeKey}
                expanded={[0]}
                generateNodeProps={({ node, path }) => ({
                    title: (
                        <>
                            <p>
                                <Button
                                    style={suggestedSelectedNode?.uid == node.uid ? { backgroundColor: '#B000D1' } : {}}
                                    onClick={
                                        () => {
                                            setSuggestedSelectedNode(node);
                                            onNodeClick(node.uid, path, false)
                                        }
                                    }
                                >
                                    {node.name}&nbsp;
                                    {node.age ? '(' + node.age + ')' : ''}
                                </Button>&nbsp;
                                {!node.matching_uid ? <Button style={{ backgroundColor: '#8B4513' }} onClick={() => addRelative(node, path)}>ADD</Button> : ''}
                                {node.matching_uid ? <Button style={{ backgroundColor: '#8B4513' }} onClick={() => updateDetails(node.matching_uid, node)}>UPDATE DETAILS</Button> : ''}
                                {node.spouse ? (
                                    <span>
                                        <span style={{ color: 'black' }}>~</span>
                                        <Button
                                            style={suggestedSelectedNode?.uid == node.spouse.uid ? { backgroundColor: '#B000D1' } : {}}
                                            onClick={
                                                () => {
                                                    setSuggestedSelectedNode(node.spouse)
                                                    onNodeClick(node.spouse.uid, path, true)
                                                }
                                            }
                                        >
                                            {node.spouse.name}&nbsp;
                                            {node.spouse.age ? '(' + node.spouse.age + ')' : ''}
                                        </Button>&nbsp;
                                        {node?.matching_uid && !node?.spouse?.matching_uid ? <Button style={{ backgroundColor: '#8B4513' }} onClick={() => addSpouseFromSuggestedData(node?.matching_uid, node?.spouse)}>ADD</Button> : ''}
                                        {node?.matching_uid && node?.spouse?.matching_uid ? <Button style={{ backgroundColor: '#8B4513' }} onClick={() => updateDetails(node?.spouse?.matching_uid, node?.spouse)}>UPDATE DETAILS</Button> : ''}
                                    </span>
                                ) : (
                                    ''
                                )}



                            </p>
                            {/* <Button appearance="outline" shape={'Round'} size={'Small'} size='mini' Status={'Info'} circular icon='add' onClick={(e) => { e.preventDefault(); e.stopPropagation(); insertNewNode(path) }}> Add Son </Button>
                                  <Button appearance="outline" shape={'Round'} size={'Small'} size='mini' Status={'Info'} circular icon='trash' onClick={(e) => { e.preventDefault(); e.stopPropagation(); removeNode(path) }} > Add Daughter </Button> */}
                        </>
                    ),
                })}
            />


        </>
    )

    return <><br />{isLoading ? <LoadingSpinner message={'Loading Data...'} /> : sortableTree}</>
}

export default tree;
