import React, { useState, useEffect } from 'react';
import SortableTree, { toggleExpandedForAll, getFlatDataFromTree } from 'react-sortable-tree';
import { Button } from '@paljs/ui/Button';
import { getsuggestedNodeDetails, getFamilyFromGeneralSamaj } from '../../actions/suggestedNodeActions';
import LoadingSpinner from '../loader';
import Tree from '../../components/sortable-tree/tree'
import _ from 'lodash';
import { mergeFamilyDetails, lookup_child } from '../../helpers/merge_family_test';
import cloneDeep from 'lodash/cloneDeep';
import { getFamilyInfo } from '../../actions/familyTreeAction';
import { koot_falan } from '../../helpers/utils';

const SuggestedTreeNodes = ({ selectedNode, village, samaj, selectedPath, suggestedRelationObj, addRelationFromSuggestedData, card_number = null }) => {
    const [treeData, setTreeData] = useState([]);
    const [suggestedGeneralSamajTreeData, setSuggestedGeneralSamajTreeData] = useState([])
    const [statusMessage, setStatusMessage] = useState('')
    const [isLoading, setLoader] = useState(false);
    const [suggestedSelectedNode, setSuggestedSelectedNode] = useState();
    const [statusMessageGenealSamaj, setStatusMessageGeneralSamaj] = useState('')
    const [originalTreeData, setOriginalTreeData] = useState([]);

    useEffect(() => {
        getsuggestedNodes();
    }, [selectedNode]);

    const getPathsForEachNode = (nodeData, pathsData = {}, path = []) => {
        // let path = [];
        path.push(nodeData.uid);
        pathsData[nodeData.uid] = _.cloneDeep(path);
        let children = nodeData.children || [];
        for (let i = 0; i < children.length; ++i) {
            getPathsForEachNode(children[i], pathsData, path);
        }
        path.pop();
        return pathsData;
    };

    const mergeInFinalTree = (finalMergedTreeData, familyToCheck) => {
        let isMerged = false;
        for (let i = 0; i < finalMergedTreeData.length; i++) {
            let existingFamilyData = finalMergedTreeData[i];
            if (existingFamilyData.family_id == familyToCheck.family_id) {
                // If merge is possible, then merge it
                let mergedRootNodesData = {};

                if (lookup_child(existingFamilyData, familyToCheck.uid)) {
                    mergedRootNodesData = mergeFamilyDetails(existingFamilyData, familyToCheck, familyToCheck.uid);
                } else if (lookup_child(familyToCheck, existingFamilyData.uid)) {
                    mergedRootNodesData = mergeFamilyDetails(familyToCheck, existingFamilyData, existingFamilyData.uid);
                }
                if (!_.isEmpty(mergedRootNodesData)) {
                    finalMergedTreeData[i] = mergedRootNodesData;
                    isMerged = true;
                    break;
                }
            }
        }
        if (!isMerged) {
            finalMergedTreeData.push(familyToCheck);
        }
    };

    const mergeRootNodesIfNeeded = (treeDataWithAPIResult) => {
        let finalMergedTreeData = [treeDataWithAPIResult[0]];
        for (let i = 1; i < treeDataWithAPIResult.length; i++) {
            mergeInFinalTree(finalMergedTreeData, treeDataWithAPIResult[i]);
        }
        return finalMergedTreeData;
    };

    const getRootIndexInTree = (rootNodeUid: string) => {
        return _.findIndex(treeData, { uid: rootNodeUid });
    };

    const getFamilyDetails = (nodeId: string, path, isSpouse = false) => {
        // Flatten the tree, get path of nodeId from it in case of spouse
        console.log('For nodeId: ' + nodeId + ', path: ' + path + ', Path first element: ' + path[0]);
        if (isSpouse) {
            let flattenedTreeData = getPathsForEachNode(treeData);
            path = flattenedTreeData[nodeId];
        }

        let indexInTree = -1;
        if (path && path.length > 0) {
            indexInTree = getRootIndexInTree(path[0]);
        }

        getSuggestedFamilyFromGeneralSamaj(nodeId, path, indexInTree);
    };

    const getSuggestedFamilyFromGeneralSamaj = async (nodeId, path, indexInTree) => {

        setLoader(true)
        const response = await getFamilyFromGeneralSamaj(samaj, nodeId, village);
        let requestedFamilyJson = response.Records.result;
        let new_node_data = lookup_child(requestedFamilyJson, nodeId);
        //setSelectedNode(new_node_data);
        //setSelectedPath(path);

        requestedFamilyJson = { ...requestedFamilyJson, expanded: true };
        if (nodeId != requestedFamilyJson.id) {
            let child_lookup = lookup_child(requestedFamilyJson, nodeId);

            if (child_lookup) {
                //CASE 2: e.g. harchandram
                // requestJSON = parent of that node i.e. nirasangram
                // so first find harchandram in requestedJSON and then set its expanded = true
                const child_lookup_id = child_lookup.id;

                const children = requestedFamilyJson.children;
                for (let i = 0; i < children.length; ++i) {
                    if (children[i].id == child_lookup_id) {
                        children[i] = { ...children[i], expanded: true };
                    }
                }
            }
        }

        // Each element in treeData array represents a family.
        let newTreeData = [];
        if (indexInTree != -1 && treeData) {
            const selectedFamilyExistingData = treeData[indexInTree];
            const selectedFamilyExistingDataCopy = cloneDeep(selectedFamilyExistingData);
            const mergeFamilyData = mergeFamilyDetails(selectedFamilyExistingDataCopy, requestedFamilyJson, nodeId);
            newTreeData = treeData.map((obj, i: Number) => {
                if (i == indexInTree) return mergeFamilyData;
                else return obj;
            });
        } else {
            newTreeData = _.map(treeData, (f) => {
                return f;
            });
            newTreeData.push(requestedFamilyJson);
        }

        let mergedRootNodesData = newTreeData;
        if (newTreeData.length > 1) {
            mergedRootNodesData = mergeRootNodesIfNeeded(newTreeData);
        }
        setTreeData(mergedRootNodesData);
        // toast.success('All Data have been fetched successfuly - node name');

        //treeData[indexInTree]

        setLoader(false);

        // const data = [response?.Records?.result]
        // setStatusMessageGeneralSamaj(response?.status)
        // setSuggestedGeneralSamajTreeData(toggleExpandedForAll({
        //     treeData: data,
        //     expanded: true
        // }))
        // setLoader(false)
    }

    const searchItem = (sourceArray, item) => {
        console.log("check for array and item", sourceArray, item)
        const kootFalanName = koot_falan(item.name)
        const matched_item = lookForMatch(sourceArray, kootFalanName);
        if (matched_item) {
            console.log('i was here ')
            item.matching_uid = matched_item.id;
            if (item.spouse && matched_item.spouse) {
                item.spouse.matching_uid = matched_item.spouse.id
            }
        }
        if (item.children) {
            const children = item.children || [];
            for (let i = 0; i < children.length; i++) {
                const child = children[i];
                searchItem(sourceArray, child);
            }
        }

        return item;
    }

    const lookForMatch = (sourceArray, destinationKootFalanName) => {
        // const foundMatch = sourceArray.map((item, index) => 
        for (let j = 0; j < sourceArray.length; j++)
        {
            // console.log("name = ", item.name, "koot_falan_name = ", koot_falan(item.name), "source kootfalan = ", destinationKootFalanName)
            if (koot_falan(sourceArray[j].name) == destinationKootFalanName)
                return sourceArray[j];

            let children = sourceArray[j]['children'] || [];

            for (let i = 0; i < children.length; i++) {
                let child = children[i];
                let found = lookForMatch([child], destinationKootFalanName);
                if (found) {
                    return found;
                }
            }
            // If iterating didn't return any matching keys, return notFound.
            return false;
        }
    };

    const updateMatchingNodeId = (sourceTree, destinationTree) => {
        const finalTree = destinationTree.map((item, index) => {
            return searchItem(sourceTree, item);
        })
        console.log('this is updated Tree', finalTree)
        return finalTree

        // sourceTree.map((item1) => {
        //     let found = false
        //     destinationTree.map((item2, index) => {
        //         if (koot_falan(item1.name) == koot_falan(item2.name)) {
        //             found = true
        //             destinationTree[index].matching_uid = item1.id
        //             if (destinationTree[index].spouse && item1.spouse?.name && koot_falan(destinationTree[index].spouse?.name) == koot_falan(item1.spouse?.name)) {
        //                 destinationTree[index].spouse.matching_uid = item1.spouse?.id
        //             }
        //             console.log("Matching --------id ----- = ", item1.id)
        //             if (item1.children && item2.children) {
        //                 updateMatchingNodeId(item1.children, item2.children)
        //             }
        //         }
        //     })
        // })
        // return destinationTree;

    }

    const getsuggestedNodes = async () => {
        setLoader(true);

        const response = await getsuggestedNodeDetails(samaj, selectedNode.uid, village, card_number)
        console.log('----response of suggested data API', response)
        let data: any[] = []
        response?.suggested_families.map((item) => {

            const currentItem = toggleExpandedForAll({
                treeData: [item.result],
                expanded: true
            })
            data = [...data, ...currentItem]

        })
        getFamilyInfo(selectedNode.uid, async (res: any) => {
            const requestedFamilyJson = res.result;
            const finalUpdatedTree = updateMatchingNodeId([requestedFamilyJson], data)
            console.log('---------attention-------', finalUpdatedTree)
            setTreeData(finalUpdatedTree);
        })

        setStatusMessage(response?.message);

        setLoader(false);
    }

    return (
        <div>
            <div style={{ height: '100vh', color: "black" }}>
                <p>{statusMessage}</p>
                <Tree treeData={treeData} setTreeData={(data) => { setTreeData(data) }} onNodeClick={(node_id, path, isSpouse) => { getFamilyDetails(node_id, path, isSpouse) }} isLoading={isLoading} selectedPath={selectedPath} suggestedRelationObj={suggestedRelationObj} addRelationFromSuggestedData={addRelationFromSuggestedData} />
            </div>
        </div>
    )

}

export default SuggestedTreeNodes;
