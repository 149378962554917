import { getApiWrapper } from "./token-wrapper-function";

export const getsuggestedNodeDetails = async (samaj, node_id, village, card_no) => {

    let url = ''
    if (samaj == "GENERAL_SAMAJ") {
        if (card_no) {
            url = `/api/suggested_family/${node_id}?samaj=${samaj}&card_number=${card_no}`
        }else{
            url = `/api/suggested_family/${node_id}?samaj=${samaj}`
        }
    } else {
        url = `/api/suggested_family/${node_id}?samaj=${samaj}&village=${village}`
    }
    const result = await getApiWrapper(url);
    return result;
}


export const getFamilyFromGeneralSamaj = async (samaj, node_id, village) => {

    let url = ''
    if (samaj == "GENERAL_SAMAJ") {
        url = `/api/family/${samaj}/${node_id}`
    } else {
        url = `/api/family/${samaj}/${node_id}?village=${village}`
    }
    const result = await getApiWrapper(url);
    return result;
}
